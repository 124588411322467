import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountGroup,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', align: 'center' },
    { text: 'USER', value: 'last_name' },
    { text: 'EMAIL', value: 'email' },
    { text: 'ROLE', value: 'is_superuser'},
    { text: 'STATUS', value: 'is_active'},
    { text: 'LAST LOGIN', value: 'last_login'},
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })
  const userStats = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        role: roleFilter.value,
      })
      .then(response => {
        const { filteredData, total, stats } = response.data

        userListTable.value = filteredData
        totalUserListTable.value = total
        userStats.value = stats

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, roleFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = is_superuser => {
    if (is_superuser) return 'warning'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = is_active => {
    if (is_active) return 'success'

    return 'warning'
  }

  const resolveUserTotalIcon = name => {
    if (name == 'Admins') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (name == 'Active') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (name == 'Total') return { icon: mdiAccountGroup, color: 'secondary' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    userStats,
    selectedRows,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
