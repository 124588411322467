<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New User</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="userData.first_name"
            outlined
            dense
            :rules="[validators.required]"
            label="First Name"
            placeholder="John"
            hide-details="auto"
            class="mb-6"
            :error-messages="errors.first_name"
          ></v-text-field>

          <v-text-field
            v-model="userData.last_name"
            outlined
            dense
            :rules="[validators.required]"
            label="Last Name"
            placeholder="Doe"
            hide-details="auto"
            class="mb-6"
            :error-messages="errors.last_name"
          ></v-text-field>

          <v-text-field
            v-model="userData.username"
            outlined
            dense
            label="Username"
            placeholder="Username"
            hint="Will be generated from email if left blank"
            persistent-hint
            hide-details="auto"
            :error-messages="errors.username"
            class="mb-3"
            @input="errors.username = []"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required,validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
            @blur="updateUsername()"
            :error-messages="errors.email"
            @input="errors.email = []"
          ></v-text-field>

          <v-select
            v-model="userData.role"
            :rules="[validators.required]"
            label="User Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>



          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      role: 'user',
    }

    const valid = ref(false)
    const form = ref(null)
    const errors = ref([])

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const updateUsername = () => {
      if (!userData.value.username.length) {
        userData.value.username = userData.value.email.split("@")[0]
      }
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = () => {

      if (valid.value) {
        store.dispatch('app-user/addUser', userData.value).then(() => {
          emit('refetch-data')
          resetuserData()
          store.dispatch("addNotification",{message:"User was successfully added to the list.",type: "success"});
        }).catch((error) => {
          errors.value = error.response.data.error
        })

      } else {
        validate();
      }

    }

    return {
      resetuserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,
      updateUsername,
      errors,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
