<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="(total,name) in userStats"
        :key="name"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total }}
              </h2>
              <span>{{ name }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(name).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(name).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(name).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(name).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Users
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="3"
        >
          <!-- search -->
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search mb-4"
          >
          </v-text-field>

        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          class="text-right"
        >

            <v-btn
              color="success"
              class="mb-4"
              @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New User</span>
            </v-btn>


        </v-col>
      </v-row>


      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :server-items-length="totalUserListTable"
        :loading="loading"
      >

        <template #[`item.id`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.id }}</span>
        </template>

        <!-- name -->
        <template #[`item.last_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.first_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.first_name }} {{ item.last_name }}
              </router-link>
              <small>@{{ item.username }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.is_superuser`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.is_superuser)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.is_superuser)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.is_superuser)"
              >
                {{ resolveUserRoleIcon(item.is_superuser) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.is_superuser ? "Administrator" : "User" }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.is_active`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.is_active)"
            :class="`${resolveUserStatusVariant(item.is_active)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.is_active ? "Active" : "Inactive" }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name : 'apps-user-edit', params : { id : item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Settings</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'
import userStoreModule from '../userStoreModule'

import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      userStats,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Administrator', value: "admin" },
      { title: 'User', value: "user"},
    ]

    const statusOptions = [
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]

    return {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      statusFilter,
      totalUserListTable,
      roleOptions,
      statusOptions,
      loading,
      options,
      userStats,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
